import { useEffect, useState } from 'react';
import CTA from './components/CTA';
import Word from './components/Word';
import config from './config.json';

const RETRIES = parseInt(config['retries'] || 3) - 1;
const TIME = parseInt(config['time'] || 10);

function App() {
  const [words, setWords] = useState([]);
  const [timer, setTimer] = useState(TIME);
  const [values, setValues] = useState([]);
  const [tries, setTries] = useState(RETRIES);
  const [states, setStates] = useState([]);
  const [gameState, setGameState] = useState("start");

  const setOneValue = (i, v) => {
    const newValues = [...values];
    newValues[i] = v;
    setValues(newValues);
  }

  const submitWords = () => {
    if (gameState === 'gameover') {
      setGameState("start");
      return;
    }
    const newStates = [...states];
    values.forEach((value, i) => {
      if (words[i] === value) newStates[i] = "valid";
      else if (words.includes(value)) newStates[i] = "invalid";
    });
    setStates(newStates);
    const allValid = newStates.reduce((prev, curr) => curr === 'valid' ? prev + 1 : prev, 0) === words.length;
    if (!allValid) {
      if (tries <= 0) setGameState("gameover");
      else setTries(state => state - 1);
    } else {
      setGameState("win");
    }
  }

  useEffect(() => {
    if (gameState !== 'ingame') return;
    if (timer <= 0) {
      setWords([...words].sort(() => Math.random() - 0.5));
      return;
    };
    setTimeout(() => {
      setTimer(timer - 1)
    }, 1000)
  }, [timer, gameState]);

  useEffect(() => {
    if (gameState !== 'start') return;
    const _words = [...config['words']].sort(() => Math.random() - 0.5);
    setWords(_words);
    setValues(new Array(_words.length).fill(""))
    setStates(new Array(_words.length).fill("normal"));
    setTries(RETRIES);
    setTimer(TIME);
  }, [gameState]);

  return (
    <div className="flex flex-col justify-center items-center h-screen gap-4">
      <div className='absolute top-0 left-0 p-4'>
        <img src='/logo.png' alt="logo" width={160} height={63} />
      </div>
      {gameState === 'start' && (
        <div>
          <button
            className="bg-indigo-600 text-white p-2 px-6 rounded hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={_ => setGameState("ingame")}
          >
            Ready!
          </button>
        </div>
      )}
      {gameState === "win" && (
        <CTA />
      )}
      {(gameState !== 'win' && gameState !== 'start') && (
        <>
          <div className='text-white text-xl text-gray-200 font-bold'>
            {timer ? (
              <>Time: {timer}</>
            ) : (
              <>{gameState === "gameover" ? "Game over" : "Guess the Seed Phrase"}</>
            )}
          </div>
          <div className={`grid gap-4 justify-center items-center w-full`} style={{ gridTemplateColumns: `repeat(auto-fill, 300px)` }}>
            {words.map((word, i) => (
              <Word
                fadeMode={timer === 0}
                word={word}
                value={values[i]}
                setValue={e => setOneValue(i, e.target.value)}
                state={states[i]}
              />
            ))}
          </div>
          {!timer && (
            <div>
              <button className="bg-indigo-600 text-white p-2 px-6 rounded hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed" onClick={submitWords}>
                <div>{gameState !== 'gameover' ? "What's the Seed Phrase?" : "Restart"}</div>
                {gameState !== 'gameover' && <div className="text-xs text-gray-300">Retries left: {tries + 1}</div>}
              </button>
            </div>
          )}
        </>
      )}
    </div >
  );
}

export default App;
