import config from '../config.json';

export default function CTA() {
    return (
        <div className="p-8 bg-gray-100 min-w-[400px] text-center rounded">
            <div className="mb-8">
                {config['cta.message']}
            </div>
            <a 
                className="bg-indigo-600 text-white p-2 px-6 rounded hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed"
                href={config['cta.buton.url']}
            >
                {config['cta.button.text']}
            </a>
        </div>
    )
}