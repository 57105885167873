export default function Word({ word, fadeMode, state, value, setValue }) {

    const stateClassName = {
        valid: 'bg-green-200',
        invalid: 'bg-red-200',
        normal: 'bg-gray-200'
    }[state];

    return (
        <div className={`${stateClassName} p-4 w-max-content rounded`}>
            {fadeMode ? (
                <input className="rounded border border-black px-2 py-1 w-full" placeholder="Enter Word" value={value} onChange={setValue}/>
            ) : (
                <span className="text-gray-600">{word}</span>
            )}
        </div>
    )
}